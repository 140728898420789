/**
 * @generated SignedSource<<19acfdbed68e07d4ec8c602ae5c9a176>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraIntegrationLinkQuery$variables = {
  key: string;
  readFromAgsEnabled: boolean;
};
export type JiraIntegrationLinkQuery$data = {
  readonly project: {
    readonly ari: string;
    readonly fusion?: {
      readonly issue: {
        readonly href: string | null;
        readonly key: string | null;
      } | null;
      readonly issueAri: string;
    } | null;
    readonly integration: {
      readonly id: string;
      readonly issue: {
        readonly href: string | null;
      } | null;
    } | null;
    readonly uuid: string;
  } | null;
};
export type JiraIntegrationLinkQuery = {
  response: JiraIntegrationLinkQuery$data;
  variables: JiraIntegrationLinkQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "readFromAgsEnabled"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "key"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "ari"
},
v3 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "href"
},
v6 = {
  "concreteType": "ProjectFusion",
  "kind": "LinkedField",
  "name": "integration",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "concreteType": "GqlJiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        (v5/*: any*/)
      ]
    }
  ]
},
v7 = {
  "condition": "readFromAgsEnabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "concreteType": "FusionDetails",
      "kind": "LinkedField",
      "name": "fusion",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "issueAri"
        },
        {
          "concreteType": "GqlJiraIssue",
          "kind": "LinkedField",
          "name": "issue",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "key"
            },
            (v5/*: any*/)
          ]
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "JiraIntegrationLinkQuery",
    "selections": [
      {
        "alias": "project",
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "projectByKey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JiraIntegrationLinkQuery",
    "selections": [
      {
        "alias": "project",
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "projectByKey",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "cacheID": "c4d8b0676a68a70209ee5fe296fecbc7",
    "id": null,
    "metadata": {},
    "name": "JiraIntegrationLinkQuery",
    "operationKind": "query",
    "text": "query JiraIntegrationLinkQuery(\n  $key: String!\n  $readFromAgsEnabled: Boolean!\n) {\n  project: projectByKey(key: $key) {\n    ari\n    uuid\n    integration {\n      id\n      issue {\n        href\n      }\n    }\n    fusion @include(if: $readFromAgsEnabled) {\n      issueAri\n      issue {\n        key\n        href\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4966f7bd11d34c537c98dd6b2894afb";

export default node;
