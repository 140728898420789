/**
 * @generated SignedSource<<d81dd530d611d94fb612a756ba8640e6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type deleteProjectFusionV2Input = {
  clientMutationId?: string | null;
  issueAri?: string | null;
  projectAri: string;
};
export type DeleteProjectFusionV2Mutation$variables = {
  input: deleteProjectFusionV2Input;
};
export type DeleteProjectFusionV2Mutation$data = {
  readonly deleteProjectFusionV2: {
    readonly project: {
      readonly fusion: {
        readonly synced: boolean;
      } | null;
      readonly key: string;
      readonly workTrackingLinks: {
        readonly " $fragmentSpreads": FragmentRefs<"ProjectLinks">;
      } | null;
    } | null;
  } | null;
};
export type DeleteProjectFusionV2Mutation = {
  response: DeleteProjectFusionV2Mutation$data;
  variables: DeleteProjectFusionV2Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "key"
},
v3 = {
  "concreteType": "FusionDetails",
  "kind": "LinkedField",
  "name": "fusion",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "synced"
    }
  ]
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "type",
    "value": "WORK_TRACKING"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "DeleteProjectFusionV2Mutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "deleteProjectFusionPayload",
        "kind": "LinkedField",
        "name": "deleteProjectFusionV2",
        "plural": false,
        "selections": [
          {
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": "workTrackingLinks",
                "args": (v4/*: any*/),
                "concreteType": "LinkConnection",
                "kind": "LinkedField",
                "name": "links",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "ProjectLinks"
                  }
                ],
                "storageKey": "links(first:1,type:\"WORK_TRACKING\")"
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteProjectFusionV2Mutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "deleteProjectFusionPayload",
        "kind": "LinkedField",
        "name": "deleteProjectFusionV2",
        "plural": false,
        "selections": [
          {
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": "workTrackingLinks",
                "args": (v4/*: any*/),
                "concreteType": "LinkConnection",
                "kind": "LinkedField",
                "name": "links",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "LinkEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "Link",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "name"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "url"
                          }
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "links(first:1,type:\"WORK_TRACKING\")"
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "80aff59faa73ddaf6f9fffd4620f7c5a",
    "id": null,
    "metadata": {},
    "name": "DeleteProjectFusionV2Mutation",
    "operationKind": "mutation",
    "text": "mutation DeleteProjectFusionV2Mutation(\n  $input: deleteProjectFusionV2Input!\n) {\n  deleteProjectFusionV2(input: $input) {\n    project {\n      key\n      fusion {\n        synced\n      }\n      workTrackingLinks: links(type: WORK_TRACKING, first: 1) {\n        ...ProjectLinks\n      }\n      id\n    }\n  }\n}\n\nfragment ProjectLinks on LinkConnection {\n  edges {\n    node {\n      id\n      name\n      url\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "abdaaa24162aba9f27df0aeb5cec4129";

export default node;
