import { commitMutation, Environment, graphql, MutationConfig } from 'relay-runtime';

import {
  DeleteProjectFusionV2Mutation$variables,
  DeleteProjectFusionV2Mutation,
} from './__generated__/DeleteProjectFusionV2Mutation.graphql';

export function deleteProjectFusionV2Mutation(
  environment: Environment,
  variables: DeleteProjectFusionV2Mutation$variables,
  events?: Pick<MutationConfig<DeleteProjectFusionV2Mutation>, 'onCompleted' | 'onError'>,
) {
  return commitMutation<DeleteProjectFusionV2Mutation>(environment, {
    mutation: graphql`
      mutation DeleteProjectFusionV2Mutation($input: deleteProjectFusionV2Input!) {
        deleteProjectFusionV2(input: $input) {
          project {
            key
            fusion {
              synced
            }
            workTrackingLinks: links(type: WORK_TRACKING, first: 1) {
              ...ProjectLinks
            }
          }
        }
      }
    `,
    variables,
    updater: store => {
      // update store to set fusion synced to false
      const projectFusion = store
        .getRootField('deleteProjectFusionV2')
        ?.getLinkedRecord('project')
        ?.getLinkedRecord('fusion');
      if (projectFusion) {
        projectFusion.setValue(false, 'synced');
      }
    },
    onCompleted: events?.onCompleted,
    onError: events?.onError,
  });
}
